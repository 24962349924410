export const DeviceMdmStatus = {
  DRAFT: "DRAFT",
  SUBSCRIBED: "SUBSCRIBED",
  DOWNLOADED: "DOWNLOADED",
  IDENTIFIED: "IDENTIFIED",
  ENROLLMENT_IN_PROGRESS: "ENROLLMENT_IN_PROGRESS",
  ENROLLED: "ENROLLED",
  UNENROLLED: "UNENROLLED",
  DEVICE_WIPE_PENDING: "DEVICE_WIPE_PENDING",
};

export const FilterDeviceMdmStatus = {
  NONE: "-",
  READY_FOR_INSTALL: "READY_FOR_INSTALL",
  INSTALLING: "INSTALLING",
  INSTALL_FAILED: "INSTALL_FAILED",
  INSTALLED: "INSTALLED",
  UNINSTALLED: "UNINSTALLED",
  WIPING: "WIPING",
  BLOCKING_PENDING: "BLOCKING_PENDING",
  BLOCKED: "BLOCKED",
  ENROLLMENT_IN_PROGRESS: "ENROLLMENT_IN_PROGRESS",
};

export const INSTALLATION_STATUSES = [
  DeviceMdmStatus.SUBSCRIBED,
  DeviceMdmStatus.IDENTIFIED, 
  DeviceMdmStatus.DOWNLOADED,
  DeviceMdmStatus.ENROLLMENT_IN_PROGRESS
];

export const MANAGED_DEVICE_STATUSES = [
  DeviceMdmStatus.ENROLLED,
  DeviceMdmStatus.DEVICE_WIPE_PENDING,
];

export const WAITING_INSTALLATION_STATUSES = [
  DeviceMdmStatus.SUBSCRIBED,
  DeviceMdmStatus.IDENTIFIED,
  DeviceMdmStatus.DOWNLOADED,
  DeviceMdmStatus.ENROLLMENT_IN_PROGRESS,
  DeviceMdmStatus.UNENROLLED
];

export const isDeviceLockPending = (device) => {
  return device.device_mdm.lock_date != null && !device.device_mdm.is_locked;
};

export const isDeviceLocked = (device) => {
  return device.device_mdm.is_locked === true;
};

export const getDeviceMdmStatus = (device) => {
  
  if (!device.device_mdm) {
    return FilterDeviceMdmStatus.NONE;
  }

  if (isDeviceLocked(device)) {
    return FilterDeviceMdmStatus.BLOCKED;
  }

  if (isDeviceLockPending(device)) {
    return FilterDeviceMdmStatus.BLOCKING_PENDING;
  }

  if (device.device_mdm.status === DeviceMdmStatus.DEVICE_WIPE_PENDING) {
    return FilterDeviceMdmStatus.WIPING;
  }

  if (device.device_mdm.status === DeviceMdmStatus.ENROLLED) {
    return FilterDeviceMdmStatus.INSTALLED;
  }

  if (device.device_mdm.status === DeviceMdmStatus.UNENROLLED) {
    return FilterDeviceMdmStatus.UNINSTALLED;
  }

  if (device.device_mdm.status === DeviceMdmStatus.SUBSCRIBED) {
    return FilterDeviceMdmStatus.READY_FOR_INSTALL;
  }
  if (device.device_mdm?.status === DeviceMdmStatus.ENROLLMENT_IN_PROGRESS) {
    return FilterDeviceMdmStatus.ENROLLMENT_IN_PROGRESS;
  }

  if ([DeviceMdmStatus.IDENTIFIED, DeviceMdmStatus.DOWNLOADED].includes(device.device_mdm.status)) {
    if (device.device_mdm.status === DeviceMdmStatus.DOWNLOADED && 
        device.mails?.some(mail => mail.campaign_id === "mdm_enrollment_retry")) {
      return FilterDeviceMdmStatus.INSTALL_FAILED;
    }
    return FilterDeviceMdmStatus.INSTALLING;
  }

  return FilterDeviceMdmStatus.NONE;
};

export const MDM_LABELS = {
  [FilterDeviceMdmStatus.NONE]: "-",
  [FilterDeviceMdmStatus.READY_FOR_INSTALL]: "Prêt pour installation",
  [FilterDeviceMdmStatus.INSTALLING]: "Installation en cours",
  [FilterDeviceMdmStatus.INSTALL_FAILED]: "Installation non aboutie",
  [FilterDeviceMdmStatus.INSTALLED]: "Installé",
  [FilterDeviceMdmStatus.UNINSTALLED]: "Désinstallé",
  [FilterDeviceMdmStatus.WIPING]: "Réinitialisation en cours",
  [FilterDeviceMdmStatus.BLOCKING_PENDING]: "Bloquage en cours",
  [FilterDeviceMdmStatus.BLOCKED]: "Bloqué",
  [FilterDeviceMdmStatus.ENROLLMENT_IN_PROGRESS]: "Installation non finalisée",
};

// Filter options for MDM column
export const MDM_FILTER_OPTIONS = [
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.NONE],
    value: FilterDeviceMdmStatus.NONE,
    filter: (device) => !device.device_mdm
  },
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.READY_FOR_INSTALL],
    value: FilterDeviceMdmStatus.READY_FOR_INSTALL,
    filter: (device) => device.device_mdm?.status === DeviceMdmStatus.SUBSCRIBED
  },
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.INSTALLING],
    value: FilterDeviceMdmStatus.INSTALLING,
    filter: (device) => [DeviceMdmStatus.IDENTIFIED, DeviceMdmStatus.DOWNLOADED].includes(device.device_mdm?.status) &&
      !(device.device_mdm?.status === DeviceMdmStatus.DOWNLOADED && 
        device.mails?.some(mail => mail.campaign_id === "mdm_enrollment_retry"))
  },
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.INSTALL_FAILED],
    value: FilterDeviceMdmStatus.INSTALL_FAILED,
    filter: (device) => device.device_mdm?.status === DeviceMdmStatus.DOWNLOADED &&
      device.mails?.some(mail => mail.campaign_id === "mdm_enrollment_retry")
  },
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.INSTALLED],
    value: FilterDeviceMdmStatus.INSTALLED,
    filter: (device) => device.device_mdm?.status === DeviceMdmStatus.ENROLLED
  },
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.UNINSTALLED],
    value: FilterDeviceMdmStatus.UNINSTALLED,
    filter: (device) => device.device_mdm?.status === DeviceMdmStatus.UNENROLLED
  },
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.WIPING],
    value: FilterDeviceMdmStatus.WIPING,
    filter: (device) => device.device_mdm?.status === DeviceMdmStatus.DEVICE_WIPE_PENDING
  },
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.BLOCKING_PENDING],
    value: FilterDeviceMdmStatus.BLOCKING_PENDING,
    filter: (device) => isDeviceLockPending(device)
  },
  {
    label: MDM_LABELS[FilterDeviceMdmStatus.BLOCKED],
    value: FilterDeviceMdmStatus.BLOCKED,
    filter: (device) => isDeviceLocked(device)
  },
];