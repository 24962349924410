import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

const Context = createContext({});

/**
 * @returns {{toasts: Array<{message: string, severity: string, uid: number}>, closeToast: (uid: number) => void, addToast: (message: string, severity?: string) => void}}
 */
export const useToastContext = () => useContext(Context);

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const closeToast = (uid) => {
    setToasts((prevToasts) => prevToasts.filter((t) => t.uid !== uid));
  };

  const addToast = useCallback(
    (message, severity = "error") => {
      const toast = { message, severity, uid: Date.now() };

      // Add toast
      setToasts([...toasts, toast]);
    },
    [toasts],
  );

  const values = useMemo(
    () => ({
      toasts,
      closeToast,
      addToast,
    }),
    [toasts, addToast],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
