import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import AuthService from "src/components/auth/auth-service";

const Context = createContext({});

/**
 * @returns {{user: User, updateUser: (user: User) => void, reloadUser: () => Promise<void>}}
 */
export const useUserContext = () => useContext(Context);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const fetchUser = useCallback(async () => {
    const service = new AuthService();

    service.load();

    try {
      const u = await service.loggedin();

      setUser(u);
    } catch (err) {
      setUser(false);
    }
  }, [setUser]);

  useEffect(() => {
    if (user === null) {
      fetchUser();
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener("accessToken_storage", fetchUser);

    return () => window.removeEventListener("accessToken_storage", fetchUser);
  });

  const values = useMemo(
    () => ({
      user,
      updateUser: setUser,
      reloadUser: fetchUser,
    }),
    [user, fetchUser],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
