// Sentry has to be the first import
// see: https://docs.sentry.io/platforms/javascript/guides/react/#configure
import "./sentry";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider, MutationCache } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import "moment/locale/fr";
import { LicenseInfo } from "@mui/x-license-pro";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider, theme } from "tarmac-v3";

import ScrollToTop from "src/tools/ScrollToTop";
import MainLoader from "src/components/MainLoader";
import { UserProvider } from "src/contexts/UserContext";
import { ToastProvider } from "src/contexts/ToastContext";

import App from "./App";

async function startApp() {
  // Set MUI License Key
  LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI_LICENSE_ID);

  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onSuccess: (_data, _variables, _context, mutation) => {
        queryClient.invalidateQueries({
          queryKey: mutation.options.mutationKey,
        });
      },
    }),
  });

  const root = createRoot(document.getElementById("root"));

  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Suspense fallback={<MainLoader />}>
            <IntercomProvider
              appId={import.meta.env.REACT_APP_INTERCOM_APP_ID}
              autoBoot={false}
              shouldInitialize={false}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                  <ScrollToTop>
                    <UserProvider>
                      <ToastProvider>
                        <App />
                      </ToastProvider>
                    </UserProvider>
                  </ScrollToTop>
                </ThemeProvider>
              </LocalizationProvider>
            </IntercomProvider>
          </Suspense>
        </Router>
        {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </StrictMode>,
  );
}

startApp();
